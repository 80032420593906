.MuiInputBase-input {
  font-size: 12px;
}

.button {
  padding: 1em 1.5em;
  font-size: 1em;
  font-weight: 400;
  font-family: "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  cursor: pointer;
  appearance: none;
  border: none;
  color: #ffffff;
  margin-bottom: 20px;
}

.button-first {
  background: linear-gradient(to right, #e55d87, #5fc3e4);
  box-shadow: 0 2px 25px rgba(229, 93, 135, 0.5);
}

.button-first:active {
  background: linear-gradient(
    to right,
    rgba(229, 93, 135, 0.9),
    rgba(95, 195, 228, 0.9)
  );
}

.button:focus {
  outline: none;
}

particle {
  left: 0;
  top: 0;
  pointer-events: none;
  position: fixed;
  opacity: 0;
}
.paragrahsec {
  p {
    span {
      font-size: 14px !important;
    }
  }
}
